<template>
  <div class="InviteUsers">
    <tags-list
      :separator="separator"
      :value="form.newUsers"
      :validator="newUserValidator"
      name="user_emails"
      placeholder="john@doe.com, jane@doe.com"
      class="m-b-m"
      @input="addNewUserToList"
    />
    <div class="columns">
      <div class="column is-narrow">
        <el-switch v-model="form.readOnly" :active-text="$t('components.brand_management.read_only')" />
      </div>
      <div class="column is-narrow">
        <el-switch v-model="form.skipConfirmation" :active-text="$t('components.brand_management.skip_confirmation')" data-testid="skipMemberInvitation" />
      </div>
      <div class="column is-narrow m-l-a">
        <v-button
          :loading="isInvitingUsers"
          class="is-primary"
          data-testid="inviteUsersTrigger"
          @click="inviteUsers"
        >
          {{ $t('forms.invite_users') }}
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import TagsList from '@/components/core/TagsList'
import { commaSeparatedListValidator } from '@hypefactors/shared/js/utils/validation'
import { email } from 'vuelidate/lib/validators'

const SEPARATOR = /[;,]/

export default {
  components: {
    TagsList
  },
  props: {
    members: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      isInvitingUsers: false,
      form: {
        skipConfirmation: false,
        newUsers: [],
        readOnly: false
      },
      separator: SEPARATOR,
      /**
       * Validator function to validate each email in the list
       * @return {{email: email}}
       */
      newUserValidator: {
        email: commaSeparatedListValidator(email, SEPARATOR)
      }
    }
  },
  methods: {
    async inviteUsers () {
      if (!this.form.newUsers.length) return this.$notify.warning(this.$t('warnings.invite_users_add_users_first'))
      try {
        await this.$confirm(this.$t('components.invite_users.confirm_inviting_users_to_brand'))
        this.isInvitingUsers = true
        const payload = {
          emails: this.form.newUsers,
          read_only: this.form.readOnly,
          skip_confirmation: this.form.skipConfirmation
        }
        await this.$api.post(`brands/${this.activeBrandId}/invitations`, payload)
        this.$emit('invited', payload)
        this.$notify.success(this.$t('success.users_invited_to_brand'))
        this.form.newUsers = []
        this.form.skipConfirmation = false
        this.form.readOnly = false
      } catch (e) {
        if (e === 'cancel') return
        this.$displayRequestError(e, this.$t('errors.users_not_invited_to_brand'))
      } finally {
        this.isInvitingUsers = false
      }
    },
    addNewUserToList (listOfUsers) {
      const forInvitation = []
      const alreadyMembers = []
      listOfUsers.forEach(mail => {
        mail = mail.trim()
        const isMember = this.members.findIndex(m => m.email === mail) !== -1
        isMember ? alreadyMembers.push(mail) : forInvitation.push(mail)
      })
      if (alreadyMembers.length) {
        this.$notify.warning(this.$t('components.invite_users.members_already_invited', { emails: alreadyMembers.join(', ') }))
      }

      this.form.newUsers = forInvitation
    }
  }
}
</script>
